export const ICON_SPRITE_URL = window.nbb.iconSpriteUrl || '/icons/icons.svg';
export const LANG = document.documentElement.lang || 'de';
export const BREAKPOINTS = {
  s: '0px',
  m: '480px',
  l: '768px',
  xl: '990px',
  xxl: '1440px',
  navigation: '1250px',
  masonry: '650px',
};

export function getBreakpointsWithFontZoom(zoom) {
  if (zoom === 150) {
    return {
      s: '480px',
      m: '768px',
      l: '990px',
      xl: '1440px',
      xxl: '1800px',
      navigation: '1800px',
      masonry: '1440px',
    };
  }
  if (zoom === 200) {
    return {
      s: '480px',
      m: '768px',
      l: '1440px',
      xl: '1800px',
      xxl: '2230px',
      navigation: '2230px',
      masonry: '1800px',
    };
  }

  return BREAKPOINTS;
}
